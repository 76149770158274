body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.keypad {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.keypad-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.keypad-key {
  width: 100px; /* Default Width */
  height: 70px; /* Default Height */

  align-items: center;
  border: 1px solid #888;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 3px;
  padding: 8px;
}

h1{
  text-align: center;
}
.red {
  color: red;
}
.green {
  color: limegreen;
}

.yellow {
  color: orange;
}

.red_bg {
  background-color: red;
}
.green_bg {
  background-color: limegreen;
}

.yellow_bg {
  background-color: orange;
}

.question-field {
  font-size: 46px;
  text-align: center;
}

.level-display {
  font-size: 26px;
  text-align: right;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.score-display {
  font-size: 26px;
  text-align: right;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.scoring-level {
  text-align: end;
}
.ready-container {
  text-align: center;
}
.timer-display {
  font-size: 26px;
  display: block;

}
.progressBar {

  margin: 0 auto;
  position: relative;
}
